import React from "react"
import { graphql } from "gatsby"
import { css } from "@emotion/core"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import ContactForm from "../components/ContactForm"
import PageHeader from "../components/PageHeader"

export const query = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }

    sanityFaqs {
      title
      faqsImage {
        imageUpload {
          asset {
            id
          }
          hotspot {
            x
            y
          }
        }
        imageDescription
      }
      questionsAnswers {
        question
        answer
      }
    }
  }
`

function faqs({ data }) {
  const QuestionAnswer = styled.div`
    margin-bottom: var(--size-8);
  `

  const Question = styled.h2`
    font-size: var(--size-4);
    font-weight: 700;
    margin-bottom: var(--size-1);
  `

  const faqs = data.sanityFaqs

  return (
    <Layout>
      {/* <div className="pageHeader">
        <h1>{faqs.title}</h1>
      </div> */}

      <PageHeader
        overlay
        backgroundImage={faqs.faqsImage.imageUpload}
        title={faqs.title}
      />
      {/* <h1>{faqs.title}</h1> */}

      <div
      // className="sectionContent"
      >
        {faqs.questionsAnswers.map((qa, index) => (
          <div
            className="sectionContent"
            css={css`
              max-width: 800px;
            `}
            key={index}
          >
            <QuestionAnswer>
              <Question>{qa.question}</Question>
              <div>{qa.answer}</div>
            </QuestionAnswer>
          </div>
        ))}
      </div>
      <div
        className="sectionContent"
        css={css`
          margin-top: var(--size-11);
          max-width: 800px;
        `}
      >
        <ContactForm />
      </div>
    </Layout>
  )
}

export default faqs
